export default [
  'Pintura',
  'Dibujo',
  'Acuarela',
  'Volumen',
  'Grabado',
  'Talleres',
  'Creatividad',
  'Desarrollo del pensamiento divergente',
  'Pruebas de acceso',
];
